<template>
  <lf-card class="py-6 max-w-screen-md mb-8">
    <loader :isLoading="isLoading" />

    <div
      v-for="country in availableCountries"
      :key="country"
      class="country-option"
    >
      <lf-switch
        v-if="!isUnderwriter"
        class="ml-auto"
        :disabled="clientCountries[country] && selectedCountries.length <= 1"
        :name="`country_${country}`"
        :model-value="clientCountries[country]"
        @update:model-value="handleCountryChange(country, $event)"
      >
        <div class="flex items-center">
          <div class="country-icon">
            <icon-base :icon="country.toLowerCase()" height="20" width="20" />
          </div>

          <p class="text-gray-500">{{ country }}</p>
        </div>
      </lf-switch>
    </div>
  </lf-card>
</template>

<script setup lang="ts">
import type { PropType } from "vue";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useAuth } from "@/hooks/auth";
import type { IClient } from "@/models/clients";
import LfSwitch from "@/components/ui/inputs/LfSwitch.vue";

const props = defineProps({
  client: {
    type: Object as PropType<IClient>,
    required: true
  }
});

const { getters, dispatch } = useStore();
const { isClientAdmin, isUnderwriter } = useAuth();

const isLoading = ref(false);
const clientCountries = ref<Record<string, boolean>>({});

const availableCountries = computed<string[]>(
  () => getters["options/all"]?.available_widget_countries
);

const selectedCountries = computed<string[]>(() =>
  availableCountries.value.filter((country) => clientCountries.value[country])
);

const updateClient = async () => {
  const actionName = isClientAdmin
    ? "auth/updateAuthClientSettings"
    : "clients/updateClient";
  const data = {
    id: props.client.id,
    available_widget_countries: selectedCountries.value
  };

  await dispatch(actionName, isClientAdmin ? data : { data });
};

const handleCountryChange = async (country: string, isSelected: boolean) => {
  clientCountries.value[country] = isSelected;

  isLoading.value = true;

  try {
    await updateClient();
  } catch {
    // If the request fails, revert the inner value change
    clientCountries.value[country] = !isSelected;
  }

  isLoading.value = false;
};

watch(
  () => props.client.available_widget_countries,
  (widgetCountries = []) => {
    clientCountries.value = widgetCountries.reduce(
      (countries, country) => {
        countries[country] = true;
        return countries;
      },
      {} as Record<string, boolean>
    );
  },
  { immediate: true }
);
</script>

<style scoped>
.country-option {
  @apply flex items-center;
}

.country-option:not(:last-child) {
  @apply mb-6;
}

.country-icon {
  @apply h-10 w-10 flex items-center justify-center bg-gray-100 rounded-full mr-4;
}
</style>
